//我要用到的一些接口
import request from "@/utils/request"

//获取用户信息
export function user_info() {
    return request({
        url: "/users/info",
        method: "GET",
    })
}

// 企业信息添加
export function enterprise(data: object) {
    return request({
        url: "/users/enterprise",
        method: "POST",
        data
    })
}

// 企业信息添加
export function enterprise_update(data: object) {
    return request({
        url: "/users/enterprise/update",
        method: "POST",
        data
    })
}


//获取企业信息
export function enterprise_info() {
    return request({
        url: "/users/enterprise",
        method: "GET"
    })
}
//用户信息修改
export function update_info(data: object) {
    return request({
        url: "/users/info/update",
        method: "POST",
        data,
    })
}
//邮箱绑定/换绑
export function bind_email(data: object) {
    return request({
        url: "/users/bind/email",
        method: "POST",
        data,
    })
}
//修改密码
export function password_update(data: object) {
    return request({
        url: "/users/password/update",
        method: "POST",
        data,
    })
}

//修改手机号
export function phone_update(data: object) {
    return request({
        url: "/users/phone/update",
        method: "POST",
        data,
    })
}


// 登出
export function logout() {
    return request({
        url: "/auth/logout",
        method: "GET"
    })
}

//微信绑定
export function wechat_bind() {
    return request({
        url: "/users/wechat/bind",
        method: "GET",
    })
}

//微信解绑
export function wechat_unbind() {
    return request({
        url: "/users/wechat/unbind",
        method: "GET",
    })
}

//获取用户openid
export function wechat_open() {
    return request({
        url: "/users/wechat/open",
        method: "GET",
    })
}
