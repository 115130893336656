import { createStore } from 'vuex'
import {user_info} from "@/api/users";

export default createStore({
  // 需要存储的值都放在这里面
  state: {
    hasLogin: localStorage.getItem('hasLogin') ? JSON.parse(localStorage.getItem('hasLogin')??''): false, // 是否登录
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')??''): {}, // 用户信息
    token:localStorage.getItem('token') ? localStorage.getItem('token'): '',
    urlInfo:{},
    count: 0,    // 在视图中通过$store.state.count来获取
    languageList: localStorage.getItem('languageList') ? JSON.parse(localStorage.getItem('languageList')??''): {},//语言列表
    selectLanguage: localStorage.getItem('selectLanguage') ? localStorage.getItem('selectLanguage'): 'zh-CN', //当前选择的语言
    selectLanguageName: localStorage.getItem('selectLanguageName') ? localStorage.getItem('selectLanguageName'): '中文简体',//当前选中语言
    webSite: localStorage.getItem('webSite') ? JSON.parse(localStorage.getItem('webSite')??''): {},//站点相关【配置
  },
  // 相当于组件的计算属性 通过 $store.getters.doubleCount 获取计算后的值
  getters: {
    doubleCount(state) {
      return state.count * 2;
    },
  },
  // 在其他视图中通过 $store.commit('setState', 10) 使用，用于修改stor存的值
  mutations: {
    login(state, provider) { // 登录
      state.userInfo = provider
      localStorage.setItem('userInfo',JSON.stringify(provider)) // 用户信息保存到本地缓存中
      localStorage.setItem('token', provider.token) //token
      localStorage.setItem('hasLogin', 'true') //token
      window.location.href = "/"
    },
    logout(state) { // 退出登录
      localStorage.removeItem("userInfo") // 清除用户信息
      localStorage.removeItem("token") // 清除token
      localStorage.removeItem("hasLogin") // 清除是否登录
      // 跳转首页
      window.location.href = "/auth/login"
    },
    updateUserInfo(state){
      user_info().then((res)=>{
        //console.log(res)
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        state.userInfo = res.data
      })
    },
    setState(state, count) {    // 只能接受两个参数，用于修改store存的值
      state.count = count;
    },
    setLanguageList(state, provider){
      localStorage.setItem('languageList', JSON.stringify(provider))
    },
    setSelectLanguage(state, provider){
      localStorage.setItem('selectLanguage', provider)
    },
    setSelectLanguageName(state, provider){
      localStorage.setItem('selectLanguageName', provider)
    },
    setWebSite(state, provider){
      localStorage.setItem('webSite', JSON.stringify(provider))
    }
  },
  // 异步任务 不会改变state 通过 $store.dispath('doubleCount') 使用
  actions: {
    doubleCount(context) {
      context.commit("doubleCount");
    },

  },
  // store的下级store 方便大型项目复杂数据管理，这里面相当于可以在放置一个和外面这些一样的模块
  modules: {

  }
})
