export default {
  menu:{ // 登录
    home: 'Home',
    foundApply: 'Account Open',
    account: 'Account',
    accountList: 'Account List',
    accountRecord: 'Account Record',
    accountRecharge: 'Account Recharge',
    accountMonitor: 'Account Monitor',
    check: 'Check',
    checkWallet: 'Check Wallet',
    checkBalance: 'Check Balance',
    checkCreditBalance: 'Check Credit Balance',
    helpCenter: 'Help',
  }, crumb:{
    home: 'Home',
    foundApply: 'Account Open',
    Account: 'Account',
    AccountList: 'Account List',
    AccountRecord: 'Account Record',
    AccountRecharge: 'Account Recharge',
    AccountMonitor: 'Account Monitor',
    Check: 'Check',
    CheckWallet: 'Wallet',
    CheckBalance: 'Balance',
    CheckCredit: 'Credit',
  }, header:{
    Settings: 'Settings',
    LogOut: 'LogOut'
  }, home:{ // 登录
    AssetOverview: 'Asset Overview',
    wallet: 'Wallet balance',
    AdvertisingAccount: 'Advertising account balance',
    PlatformWallet: 'Platform Wallet',
    creditBalance: 'credit Balance',
    RechargeWallet: 'Recharge Wallet',
    ViewDetails: 'View Details',
    ActiveAccountBalance: 'Active Account Balance',
    FrozenBalance: 'Frozen Balance',
    PlatformGifts: 'Platform Gifts',
    UsedCredit: 'Used Credit',
    CreditLimit: 'Credit Limit',
    AccountOpeningApplication: 'Account Opening Application',
    inReview: 'inReview',
    ToBeModified: 'To BeModified',
    GoAndModifyIt: 'Go And ModifyIt',
    DisableRate:'Consumption lockdown rate',
    AccountDisableRate:'Account closure rate',
    PassedInThePast30Days: 'Passed In The Past 30 Days',
    ApplyForAccountOpening: 'Account Opening',
    PopularEntrance: 'Popular Entrance',
    AccountOpeningApplication2: 'Account Opening',
    IntelligentRecharge: 'Intelligent Recharge',
    AccountReconciliationManagement: 'Account Reconciliation Management',
    account: 'account',
    WalletRecharge: 'Wallet recharge',
    RechargeAmount: 'amount',    
    EnterAmount: 'Please enter the recharge amount',    
    PaymentMethod: 'Payment',
    Cancel:'Cancel',
    ConfirmRecharge:'Confirm Recharge',
    Message1:"The USD balance you have recharged to the platform account,",
    Message2:"Excluding amounts already recharged to media platforms",
    Message3:"The total balance of recharging to media platform advertising accounts",
    Message4:"The credit limit balance granted by the platform,",
    Message5:"The calculation pipeline is: total credit limit - used limit"
  }, login:{ // 登录
    title: 'login',
    NoAccount: 'No account？',
    register: 'register',
    WelcomeToLogin: 'Welcome to login',
    PleaseEnterYourPhoneNumberOrEmailAddress: 'Please enter your phone number or email address',
    PleaseEnterYourLoginPassword: 'Please enter your login password',
    ForgotPassword: 'Forgot password?',
    VerificationCodeLogin: 'Verification code login',
    logOn: 'log on',
    LoginSuccess:'Successfully logged in, redirecting...'
  }, register:{ // 注册
    title: 'register',
    ExistingAccount: 'Existing account?',
    GoLogIn: 'Go log in',
    WelcomeToRegister: 'Welcome to register',
    PleaseEnterYourPhoneNumber: 'Please enter your phone number (as the preferred login account)',
    ObtainVerificationCode: 'Request Verification Code',
    PleaseEnterYourFrequentlyUsedEmail: 'Please enter your frequently used email (as an alternative account, this is not mandatory)',
    PleaseEnterA6_20DigitLoginPassword: 'Please enter a 6-20 digit login password (including uppercase and lowercase letters and numbers)',
    FreeRegistration: 'Free registration',
    IHaveReadAndAmWillingToAccept: 'I have read and am willing to accept',
    TermsOfService: '《Terms of Service》',
    and: 'and',
    PrivacyStatement: '《Privacy Statement》',    
    ElMPleaseEnterYourPhoneNumber: 'Please enter your phone number',
    ElMPleaseEnterYourEmailNumber: 'Please enter your email number',
    ElMPleaseEnterTheVerificationCode: 'Please enter the verification code',
    ElMPleaseEnterPassword: 'Please enter password',
    PleaseAgreeToTheServiceAgreement: 'Please agree to the service agreement',
    SuccessfullySentSMSVerificationCode: 'Successfully sent SMS verification code',
    SuccessfullySentEmailVerificationCode: 'Successfully sent email verification code',
    UserAgreement: '《User Agreement》',
    UserAgreementAdvertisingComplianceManagementSystem: '《Advertising compliance management system》',
    ServiceAndInformationCollectionAgreement: '《Service and Information Collection Agreement》'
  },forget:{// 找回密码
    title: 'forget password',
    NoAccount: 'No Account?',
    register: 'register',
    RetrievePassword: 'Retrieve password',
    PleaseEnterYourPhoneNumber: 'Please Enter Your PhoneNumber',
    PleaseEnterTheMobileVerificationCode: 'PleaseEnterTheMobileVerificationCode',
    SuccessfullySentSMSVerificationCode: 'SuccessfullySentSMSVerificationCode',
    ObtainVerificationCode: 'Request Verification Code',
    ReturnToLogin: 'Return to login',
    VerifyWithEmail: 'Verify with email',
    PleaseEnterYourEmailNumber: 'Please Enter Your Email Number',
    PleaseEnterTheEmailVerificationCode: 'Please Enter The Email Verification Code',
    SuccessfullySentEmailVerificationCode: 'Successfully Sent Email Verification Code',
    VerifyWithMobilePhone: 'Verify with mobile phone',
    Verify: 'Verify',
    NewPassword:'Please enter a new password',
    AgainNewPassword:'Please enter the new password again',
    NewPasswordLogin:'Modification successful, please log in again',
  },loginSMS:{// 验证码登录
    title: 'login SMS',
    NoAccount: 'No account？',
    register: 'register',
    WelcomeToLogin: 'Welcome to login',
    PleaseEnterYourPhoneNumber: 'Please Enter Your PhoneNumber',
    PleaseEnterTheMobileVerificationCode: 'PleaseEnterTheMobileVerificationCode',
    SuccessfullySentSMSVerificationCode: 'SuccessfullySentSMSVerificationCode',    
    ObtainVerificationCode: 'Request Verification Code',
    PleaseEnterYourEmailAddress: 'Please enter your email address',
    PleaseEnterTheEmailVerificationCode: 'Please Enter The Email Verification Code',
    SuccessfullySentEmailVerificationCode: 'Successfully Sent Email Verification Code',
    PasswordLogin: 'Password login',
    EmailVerificationCodeLogin: 'Email verification code login',
    MobileVerificationLogin: 'Mobile verification login',
    logOn: 'logOn',
  },password:{    
    title: 'Change password',
    ChangePassword: 'Change password',
    PleaseEnterANewPassword: 'Please enter a new password of 6-20 characters (including uppercase and lowercase letters and numbers)',
    PleaseEnterANewPassword2: 'Please enter a new password',
    PleaseEnterTheNewPasswordAgain: 'Please enter the new password again',
    ConfirmModifications: 'Confirm',
    ReturnToLogin: 'Return to login',
    ModificationSuccessful: 'Modification successful, please log in again'
  },foundApply:{ // 开户申请
    OfficialAuthorizedServiceProvider: 'Official Authorized Service Provider',
    TikTok: 'Tiktok International Edition is popular on global short video social platforms, and a large number of trendy young users bring higher conversion rate through strong diversion and exposure of short video content.',
    Google: 'The worlds largest search engine company, with nearly 3 billion monthly active users, displays advertisements at the right time when customers search for your products or services.',
    Facebook: 'Paid per click or per thousand views, it runs very fast and is very user-friendly. In addition, advertisers can also quickly understand the running status of different advertisements through reports.',
    ApplyNow: 'Apply Now',
    ComingSoon: 'Coming Soon'
  },foundTikTok:{ // 开户申请TikTok
    Tip: "Business License Requirements:",
    Tip1:"1. Please provide a clear and watermark free mainland business license document, which must be the latest and authentic version.",
    Tip2:"2. Ensure that the QR code below the business license is recognizable.",
    Tip3:"3. If the photo of the submitted business license or organization code certificate is not clear or complete, it may result in a mismatch. After verifying it, please submit the account opening application",
    BusinessLicense: 'Business License',
    BusinessLicenseTip: 'Please upload the attachment of the business license',
    LicenseTip: 'A business license is a document that proves the legality of your company. Please upload PNG, JPG, JPEG files',
    CompanyRegistrationLocation: 'Company registration location',
    CompanyRegistrationLocationTip: 'Please select the company registration location',
    CompanyName: 'Company Name',
    CompanyNameTip: 'Please enter the company name',
    BusinessLicenseNumber: 'Business License Number',
    BusinessLicenseNumberTip: 'Please enter the business license number',
    AccountTimeZone: 'Account Time Zone',
    AccountTimeZoneTip: 'Please select the account time zone',
    PromotionLink: 'Promotion Link',
    PromotionLinkTip: 'Please enter the promotion link',
    PromotionLinkTip2: 'Please enter the correct link format',
    ContactEmail: 'Contact email',
    ContactEmailTip: 'Please enter the contact email',
    ContactEmailTip2: 'Please enter the correct email format',
    BusinessCenterID: 'Business Center ID',
    BusinessCenterIDTip: 'Please enter the Business Center ID',
    Reset: 'Reset',
    Submit: 'Submit',
  },AccountList:{
    Account: 'name',
    RequestID: 'ID',
    RequestIDTip: 'Please enter the Account ID',
    AccountTip: 'Please enter the account name',
    AccountEntity: 'Account opening entity',
    Media: 'Media platform',
    status: 'status',
    AccountID: 'Account Name/Account ID',
    Currency: 'Currency',
    Balance: 'Balance',
    DailyBudget: 'Daily Budget',
    AccountRecharge: 'Account Recharge',
    DeductionTransfer: 'Deduction Transfer',
    BindUnbind: 'Bind/Unbind',
    AccountRenaming: 'Account Renaming',
    Fail: 'Fail',
    Passed: 'Passed',
    InReview: 'In Review',
    Operate: 'Operate',
    Reset: 'Reset',
    Search: 'Search',
    BatchRecharge: 'Batch recharge',
    BatchZeroing: 'Batch zeroing',
    BatchBinding: 'Batch binding/unbinding',
    ViewBC: 'View BC',
    NewName: 'New name',
    NewNameTip: 'Please enter a new account name',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    ConfirmModification: 'Confirm modification',
    RechargeAmount: 'Recharge amount',
    RechargeAmountTip: 'Please enter the recharge amount',
    DailyBudgetTip: 'Please enter daily budget',
    ConfirmRecharge: 'Confirm recharge',
    ConfirmClear: 'Confirm Clear',
    AddAccount: 'Add Account',
    BCID: 'BCID',
    EnterBCID: 'Please enter BCID',
    SelectBCID: 'Please select BC ID',
    Email: 'Email',
    EnterEmail: 'Please enter email',
    EnterEmail2: 'Please enter the correct email address',
    SelectEmail: 'Please select email',
    Permission: 'Permission',
    SelectPermission: 'Please select permission',
    AccountSearch: 'Please enter your account name to search and add',
    Select: 'Select',
    Transfer: 'Account reduction transfer request',
    AccountBalance: 'Account balance',
    EstimatedAeductibleAmount: 'Estimated deductible amount',
    TransferID: 'Transfer out account name/ID',
    Type: 'Type',
    TransferAccount: 'Transfer Account',
    TransferAmount: 'Deduction',
    TransferMoney: 'Transfer Money',
    EnterTransferAmount: 'Please enter the transfer amount',
    IsOut: 'Is it all transferred out?',
    Clear: 'Clear to Zero',
    TransferredAccount: 'Transferred to account',
    SelectTransferAccount: 'Please select transfer account',
    BindBC: 'Bind BC',
    UnbindBC: 'Unbind BC',
    BindEmail: 'Bind email',
    UnbindEmail: 'Unbind email',
  },AccountRecord:{
    Media: 'media platform',
    Status: 'Status',
    AccountEntity: 'Account opening entity',
    ApplyNumber: 'Application ID',
    PromotionLink: 'Promotion Link',
    StartTime: 'Start Time',
    EndTime: 'End Time',
    Reset: 'Reset',
    Search: 'Search',
    Export: 'Export',
    AccountApplicationID: 'Company name/Application ID',
    CreatedTime: 'Created Time',
    Operate: 'Operate',
    AccountInfo: 'Account Info',
    AccountID: 'Account ID',
    AccountName: 'Account Name',
    CompanyName: 'Company Name',
    Currency: 'Currency',
    AccountBalance: 'Account Balance',
    View: 'View',
    ViewAccount: 'View Account',
    Edit: 'Edit',
    Copy: 'Copy',
  },AccountRecharge:{
    Add:'Add Account',
    AccountName:'Account Name',
    MediaPlatform:'Platform',
    MonitoringAmount:'Monitoring Amount',
    RechargeAmount:'Recharge',
    CircuitTime:'Circuit Time',
    State:'State',
    Modify:'Modify',
    Delete:'Delete',
    Operate:'Operate',
    IsDelete:'Are you sure you want to delete it',
    SelectMediaPlatform:'Please select a media platform',
    TransferAccount:'Account',
    SelectTransferAccount:'Please select the transfer account',
    MonitoringBalance:'Monitoring',
    PutMonitoringBalance:'Monitoring Balance',
    PutRechargeAmount:'Please enter the recharge amount',
    MaximumTwoDecimal:'Maximum two decimal places',
    IntervalTime:'Time',
    PutIntervalTime:'Please enter how many hours of rotation to patrol',
    Hours:'Hour',
    Cancellation:'Cancel',
    Submit:'Submit',
    PleaseEnterInteger:'Please enter an integer',
    SuccessfullyDeleted:'Successfully deleted'
  },AccountDetails:{ //开户详情
    DocumentInformation: 'Document information',
    ApplicationID: 'Application ID',
    MediaPlatform: 'Media platform',
    CreationTime: 'Creation time',
    AccountInformation: 'Account information',
    AccountType: 'Account type',
    Industry: 'Industry',
    DeliveryCountry: 'Delivery country',
    AccountTimeZone: 'Account Time Zone',
    AccountAuthorization: 'Account Authorization',
    PromotionLink: 'Promotion link',
    Authorization: 'Authorization',
    Number: 'Number',
    EmailID: 'email / ID',
    Permission: 'Permission',
    EnterpriseInformation: 'Enterprise Information',
    CompanyName: 'Company Name',
    LicenseCode: 'License Code',
    License: 'License',  
  },CheckWallet:{
    WalletRechargeOrder:'Wallet recharge order',
    AdvertisingTransactionOrders:'Advertising transaction orders',
    AdvertisingConsumptionDetails:'Advertising Consumption Details',
    WalletRecharge:'Wallet recharge',
    RechargeAmount:'Recharge amount',
    EnterRechargeAmount:'Please enter the recharge amount',
    PaymentMethod:'Payment method',
    SelectPaymentMethod:'Please select payment method',
    Cancellation:'Cancel',
    ConfirmRecharge:'Confirm recharge',
    AdvertiserIdPlaceholder:"Please enter the advertising account ID",
    Reset:"Reset",
    Search:"Search",
    StaticA:"Statistics: Total expenses:",
    StaticB:"Total number of ad impressions:",
    StatTimeDay:"Date",
    AdvertiserId:"Advertising account name/Advertising account ID",
    Spend:"Spend",
    Impressions:"Impressions",
  },CheckBalance:{ // 钱包明细
    OrderNumber: 'Order number',
    ChangeType: 'Change type',
    OperationType: 'Operation type',
    Reset: 'Reset',
    Search: 'Search',
    PreChangeAmount: 'Pre change amount',
    ChangeAmount: 'Change amount',
    AmountAfterChange: 'Amount after change',
    Remarks: 'Remarks',
  },CheckCredit:{ // 信用卡明细
    OrderNumber: 'Order number',
    ChangeType: 'Change type',
    OperationType: 'Operation type',
    Reset: 'Reset',
    Search: 'Search',
    PreChangeAmount: 'Pre change amount',
    ChangeAmount: 'Change amount',
    AmountAfterChange: 'Amount after change',
    Remarks: 'Remarks',
  },CheckAD:{ // 广告交易订单
    AccountType: 'Account type',
    OrderNumber: 'Order number',    
    AdvertisingName: 'Advertising Name',    
    ActionType: 'Operation type',    
    Status: 'Status',
    TransferStatus:'Transfer status',
    Reset: 'Reset',    
    Search: 'Search',    
    PlatformType: 'Platform Type',
    Account: 'Account',
    TransferPlatform: 'Transfer advertising account platform',    
    TransferPlatformId: 'Transfer advertising account name/Transfer advertising account ID',    
    AccountId: 'Advertising account name/Advertising account ID',    
    Remarks: 'Remarks',
    CreatedAt:'Trading Hours',
  },CheckPay:{ // 钱包充值订单
    Sn: 'Order number',
    Status:'Status',
    Reset:'Reset',
    Search:'Search',
    CurrencyName: 'Currency',
    PayTypeName: 'Payment method',
    Amount: 'Recharge amount',
    ExpiresAt:'Order expiration time',
    PayTime:'Payment Time',
    Operate:'Operate',
    ImmediatePayment:'Immediate payment',
  },Settings:{
    Account:"Account Information",
    Company:"Company Information",
    EmailBind:"Email binding",
    PasswordUpdate:"Password modification",
    Avatar:"Avatar",
    Nickname:"User nickname",
    NicknamePlaceholder:"Please enter the user nickname",
    NicknameRule:"User nickname length only supports (3-20)",
    SubmitForm:'Submit',
    Upload:"The image cannot exceed 10M",
    UpdateSuccess:"Modified successfully",
    EnterpriseName:"Enterprise Name",
    EnterpriseEmail:"Enterprise email",
    EnterprisePhone:"Enterprise Phone",
    EnterpriseAddress:"Enterprise address",
    EnterpriseNamePlaceholder:"Please enter the company name",
    EnterpriseEmailPlaceholder:"Please enter your company email address",
    EnterprisePhonePlaceholder:"Please enter the contact phone number",
    EnterpriseAddressPlaceholder:"Please enter the company address",
    Email:"Mailbox",
    OldEmailCode:"Email verification code",
    OldEmailCodePlaceholder:"Please enter the binding email verification code",
    SendSms :"Send verification code",
    BindEmail:"Bind email",
    BindEmailPlaceholder:"Please enter the bound email address",
    BindEmailCode:"Bind email verification code",
    BindEmailCodePlaceholder:"Please enter the email verification code",
    SendCodeSuccess:"Successfully sent verification code",
    EmailBindSuccess:"Email binding successful",
    EmailBindCodePlaceholder:"Please enter the email verification code",
    EmailSuccess:"Please enter the correct email address",
    OldPassword:"Old password",
    OldPasswordPlaceholder:"Please enter your old password",
    Password:"Password",
    PasswordPlaceholder:"Please enter a new password",
    RePassword:"Confirm password",
    RePasswordPlaceholder:"Please enter a confirmation password",
    PasswordUpdateSuccess:"Password modification successful",
  }
}