export default {
  menu:{ // 登錄
    home: '首頁',
    foundApply: '開戶申請',
    account: '賬戶管理',
    accountList: '賬戶列表',
    accountRecord: '開戶記錄',
    accountRecharge: '智能充值',
    accountMonitor: '帳戶監控',
    check: '對賬管理',
    checkWallet: '錢包充值記錄',
    checkBalance: '錢包明細',
    checkCreditBalance: '信用余額明細',
    helpCenter: '幫助中心',
  }, crumb:{
    home: '首頁',
    foundApply: '開戶申請',
    Account: '賬戶管理',
    AccountList: '賬戶列表',
    AccountRecord: '開戶記錄',
    AccountRecharge: '智能充值',
    AccountMonitor: '帳戶監控',
    Check: '對賬管理',
    CheckWallet: '錢包充值記錄',
    CheckBalance: '錢包明細',
    CheckCredit: '信用余額明細',
  }, header:{
    Settings: '帳號設置',
    LogOut: '退出'
  }, home:{ // 登錄
    AssetOverview: '資産概覽',
    wallet: '錢包余額',
    AdvertisingAccount: '廣告賬戶余額',
    PlatformWallet: '平台錢包',
    creditBalance: '信用余額',
    RechargeWallet: '充值錢包',
    ViewDetails: '查看明細',
    ActiveAccountBalance: '活躍賬戶余額',
    FrozenBalance: '被凍結余額',
    PlatformGifts: '平台贈金',
    UsedCredit: '已使用授信',
    CreditLimit: '授信額度',
    AccountOpeningApplication: '開戶申請',
    inReview: '審核中',
    ToBeModified: '待修改',
    GoAndModifyIt: '去修改',
    DisableRate:'消耗封戶率',
    AccountDisableRate:'帳戶封戶率',
    PassedInThePast30Days: '近30天已通過',
    ApplyForAccountOpening: '申請開戶',
    PopularEntrance: '熱門入口',
    AccountOpeningApplication2: '開戶申請',
    IntelligentRecharge: '智能充值',
    AccountReconciliationManagement: '對賬管理',
    account: '賬戶管理',
    WalletRecharge: '錢包充值',
    RechargeAmount: '充值金額',
    EnterAmount: '請輸入充值金額',
    PaymentMethod: '賬戶管理',
    Cancel:'取消',
    ConfirmRecharge:'確認充值',
    Message1:"您充值到平臺帳戶的美金餘額，",
    Message2:"不包含已經充值到媒體平臺的金額",
    Message3:"充值到媒體平臺廣告帳戶的總餘額",
    Message4:"平臺授予的信用額度餘額，",
    Message5:"計算管道為：總授信額度-已使用額度"
  }, login:{ // 登錄
    title: '登錄',
    NoAccount: '沒有賬號？',
    register: '去注冊',
    WelcomeToLogin: '歡迎登錄',
    PleaseEnterYourPhoneNumberOrEmailAddress: '請輸入手機號或郵箱',
    PleaseEnterYourLoginPassword: '請輸入登錄密碼',
    ForgotPassword: '忘記密碼？',
    VerificationCodeLogin: '驗證碼登錄',
    logOn: '登錄',
    LoginSuccess:'成功登入，正在跳轉。。。'
  }, register:{ // 注冊
    title: '注冊',
    ExistingAccount: '已有賬號？',
    GoLogIn: '去登陸',
    WelcomeToRegister: '歡迎注冊',
    PleaseEnterYourPhoneNumber: '請輸入手機號（作為登錄的首選賬號）',
    ObtainVerificationCode: '獲取驗證碼',
    PleaseEnterYourFrequentlyUsedEmail: '請輸入您常用郵箱（作為備選賬號，此項非必填）',
    PleaseEnterA6_20DigitLoginPassword: '請輸入6-20位登錄密碼（需包含大小寫字母和數字）',
    FreeRegistration: '免費注冊',
    IHaveReadAndAmWillingToAccept: '我已閱讀並願意接受',
    TermsOfService: '《服務條款》 ',
    and: '和',
    PrivacyStatement: '《隱私聲明》',
    ElMPleaseEnterYourPhoneNumber: '請輸入手機號碼',
    ElMPleaseEnterYourEmailNumber: '請輸入郵箱號碼',
    ElMPleaseEnterTheVerificationCode: '請輸入驗證碼',
    ElMPleaseEnterPassword: '請輸入密碼',
    PleaseAgreeToTheServiceAgreement: '請同意服務協議',
    SuccessfullySentSMSVerificationCode: '成功發送短信驗證碼',
    SuccessfullySentEmailVerificationCode: '成功發送郵件驗證碼',
    UserAgreement: '《使用者協定》',
    UserAgreementAdvertisingComplianceManagementSystem: '《廣告合規管理制度》',
    ServiceAndInformationCollectionAgreement: '《服務及資訊收集協定》'
  },forget:{// 找回密碼
    title: '找回密碼',
    NoAccount: '沒有賬號？',
    register: '去注冊',
    RetrievePassword: '找回密碼',
    PleaseEnterYourPhoneNumber: '請輸入手機號碼',
    PleaseEnterTheMobileVerificationCode: '請輸入手機驗證碼',
    SuccessfullySentSMSVerificationCode: '成功發送短信驗證碼',
    ObtainVerificationCode: '獲取驗證碼',
    ReturnToLogin: '返回登錄',
    VerifyWithEmail: '用郵箱驗證',
    PleaseEnterYourEmailNumber: '請輸入郵箱號碼',
    PleaseEnterTheEmailVerificationCode: '請輸入郵箱驗證碼',
    SuccessfullySentEmailVerificationCode: '成功發送郵件驗證碼',
    VerifyWithMobilePhone: '用手機驗證',
    Verify: '驗證',
    NewPassword:'請輸入新密碼',
    AgainNewPassword:'請再次輸入新密碼',
    NewPasswordLogin:'修改成功，請重新登入',
  },loginSMS:{// 驗證碼登錄
    title: '驗證碼登錄',
    NoAccount: '沒有賬號？',
    register: '去注冊',
    WelcomeToLogin: '歡迎登錄',
    PleaseEnterYourPhoneNumber: '請輸入手機號',
    PleaseEnterTheMobileVerificationCode: '請輸入手機驗證碼',
    SuccessfullySentSMSVerificationCode: '成功發送短信驗證碼', 
    ObtainVerificationCode: '獲取驗證碼',   
    PleaseEnterYourEmailAddress: '請輸入郵箱',
    PleaseEnterTheEmailVerificationCode: '請輸入郵箱驗證碼',
    SuccessfullySentEmailVerificationCode: '成功發送郵件驗證碼',
    PasswordLogin: '密碼登錄',
    EmailVerificationCodeLogin: '郵箱驗證碼登錄',
    MobileVerificationLogin: '手機驗證登錄',
    logOn: '登錄'
  },password:{ // 修改密碼
    title: '修改密碼',
    ChangePassword: '修改密碼',
    PleaseEnterANewPassword: '請輸入6-20位新密碼（需包含大小寫字母和數字）',
    PleaseEnterANewPassword2: '請輸入新密碼',
    PleaseEnterTheNewPasswordAgain: '請再次輸入新密碼',
    ConfirmModifications: '確認修改',
    ReturnToLogin: '返回登錄',
    ModificationSuccessful: '修改成功，請重新登錄'
  },foundApply:{ // 開戶申請
    OfficialAuthorizedServiceProvider: '官方授權服務商',
    TikTok: '抖音國際版，風靡全球短視頻社交平台，海量新潮年輕用戶，通過強導流強曝光的短視頻內容帶來更高轉化率。',
    Google: '全球最大的搜索引擎公司，擁有近30億月活用戶，在客戶搜索您的産品或服務的那一刻，恰當其時展示廣告。',
    Facebook: '按點擊或每千流量付費，運行速度非常快，而且非常易用。另外，廣告主還可以通過報表更快的了解不同廣告的運行狀況。',
    ApplyNow: '立即申請',
    ComingSoon: '即將開放'
  },foundTikTok:{ // 開戶申請TikTok
    Tip: "營業執照要求:",
    Tip1:"1.請盡量提供清晰且無水印的大陸地區營業執照證件，且必須是真實最新的版本",
    Tip2:"2.確保營業執照下方的二維碼可識別",
    Tip3:"3.提交的營業執照或組織機構代碼證照片不清晰或不完整會導致不匹配，核對無誤後再提交開戶申請",
    BusinessLicense: '營業執照',
    BusinessLicenseTip: '請上傳營業執照附件',
    LicenseTip: '營業執照是指證明您的公司合法性的憑證。 請上傳 PNG、JPG、JPEG 文件',
    CompanyRegistrationLocation: '公司注冊地',
    CompanyRegistrationLocationTip: '請選擇公司注冊地',
    CompanyName: '公司名稱',
    CompanyNameTip: '請輸入企業名稱',
    BusinessLicenseNumber: '營業執照編號',
    BusinessLicenseNumberTip: '請輸入營業執照編號',
    AccountTimeZone: '賬號時區',
    AccountTimeZoneTip: '請選擇賬號時區',
    PromotionLink: '推廣鏈接',
    PromotionLinkTip: '請輸入推廣鏈接',
    PromotionLinkTip2: '請輸入正確鏈接格式',
    ContactEmail: '聯系人郵箱',
    ContactEmailTip: '請輸入聯系人郵箱',
    ContactEmailTip2: '請輸入正確郵箱格式',
    BusinessCenterID: '商務中心ID',
    BusinessCenterIDTip: '請輸入商務中心ID',
    Reset: '重置',
    Submit: '提交',
  },AccountList:{
    Account: '帳號名稱',
    RequestID: '賬戶ID',
    RequestIDTip: '請輸入賬戶ID',
    AccountTip: '請輸入帳號名稱',
    AccountEntity: '開戶主體',
    Media: '媒體平台',
    status: '狀態',
    AccountID: '帳號名稱/賬戶ID',
    Currency: '幣種',
    Balance: '余額',
    DailyBudget: '每日預算',
    AccountRecharge: '賬戶充值',
    DeductionTransfer: '減款轉賬',
    BindUnbind: '綁定/解綁',
    AccountRenaming: '賬戶更名',
    Fail: '未通過',
    Passed: '已通過',
    InReview: '審核中',
    Operate: '操作',
    Reset: '重置',
    Search: '搜索',
    BatchRecharge: '批量充值',
    BatchZeroing: '批量清零',
    BatchBinding: '批量綁定/解綁',
    ViewBC: '查看BC',
    NewName: '新名稱',
    NewNameTip: '請輸入賬戶新名稱',
    Cancel: '取消',
    Confirm: '提交',
    ConfirmModification: '確認修改',
    RechargeAmount: '充值金額',
    RechargeAmountTip: '請輸入充值金額',
    DailyBudgetTip: '請輸入每日預算',
    ConfirmRecharge: '確認充值',
    ConfirmClear: '確認清空',
    AddAccount: '添加賬戶',
    BCID: 'BCID',
    EnterBCID: '請輸入BC ID',
    SelectBCID: '請選擇BC ID',
    Email: '郵箱',
    EnterEmail: '請輸入郵箱',
    EnterEmail2: '請輸入正確郵箱',
    SelectEmail: '請選擇郵箱',
    Permission: '權限',
    SelectPermission: '請選擇權限',
    AccountSearch: '請輸入帳號名稱搜索添加',
    Select: '選擇',
    Transfer: '賬戶減款轉賬申請',
    AccountBalance: '賬戶余額',
    EstimatedAeductibleAmount: '預估可減款金額',
    TransferID: '轉出賬戶名稱/ID',
    Type: '類型',
    TransferAccount: '轉賬',
    TransferAmount: '减款',
    TransferMoney: '轉出金額',
    EnterTransferAmount: '請輸入轉出金額',
    IsOut: '是否全部轉出',
    Clear: '清零',
    TransferredAccount: '轉入賬戶',
    SelectTransferAccount: '請選擇轉入賬戶',
    BindBC: '綁定BC',
    UnbindBC: '解綁BC',
    BindEmail: '綁定郵箱',
    UnbindEmail: '解綁郵箱',
  },AccountRecord:{
    Media: '媒體平台',
    Status: '狀態',
    AccountEntity: '開戶主體',
    ApplyNumber: '申請ID',
    PromotionLink: '推廣鏈接',
    StartTime: '開始時間',
    EndTime: '結束時間',
    Reset: '重置',
    Search: '搜索',
    Export: '導出',
    AccountApplicationID: '公司名稱/申請ID',
    CreatedTime: '申請時間',
    Operate: '操作',
    AccountInfo: '賬戶信息',
    AccountID: '賬戶id',
    AccountName: '賬戶名稱',
    CompanyName: '企業名稱',
    Currency: '幣種',
    AccountBalance: '賬戶余額',
    View: '查看',
    ViewAccount: '查看賬戶',
    Edit: '修改',
    Copy: '複制',
  },AccountRecharge:{
    Add:'添加賬戶',
    AccountName:'帳號名稱',
    MediaPlatform:'媒體平台',
    MonitoringAmount:'監控金額',
    RechargeAmount:'充值金額',
    CircuitTime:'巡回時間',
    State:'狀態',
    Modify:'修改',
    Delete:'刪除',
    Operate:'操作',
    IsDelete:'是否確認刪除',
    SelectMediaPlatform:'請選擇媒體平台',
    TransferAccount:'轉入賬戶',
    SelectTransferAccount:'請選擇轉入賬戶',
    MonitoringBalance:'監控余額',
    PutMonitoringBalance:'請輸入監控賬戶余額',
    PutRechargeAmount:'請輸入充值金額',
    MaximumTwoDecimal:'最大兩位小數',
    IntervalTime:'間隔時間',
    PutIntervalTime:'請輸入多少小時輪巡',
    Hours:'小時',
    Cancellation:'取消',
    Submit:'提交',
    PleaseEnterInteger:'請輸入整數',
    SuccessfullyDeleted:'成功刪除'
  },AccountDetails:{ //開戶詳情
    DocumentInformation:'單據信息',
    ApplicationID:'申請ID',
    MediaPlatform:'媒體平台',
    CreationTime:'創建時間',
    AccountInformation:'賬戶信息',
    AccountType:'賬戶類型',
    Industry:'行業',
    DeliveryCountry:'投放國家',
    AccountTimeZone:'賬戶時區',
    AccountAuthorization:'賬戶授權',
    PromotionLink:'推廣鏈接',
    Authorization:'授權',
    Number:'序號',
    EmailID:'授權郵箱/商務中心id',
    Permission:'權限',
    EnterpriseInformation:'企業信息',
    CompanyName:'開戶公司名稱（中文）',
    LicenseCode:'營業執照社會信用代碼',
    License:'營業執照附件',
  },CheckWallet:{
    WalletRechargeOrder:'錢包充值訂單',
    AdvertisingTransactionOrders:'廣告交易訂單',
    AdvertisingConsumptionDetails:'廣告消耗明細',
    WalletRecharge:'錢包充值',
    RechargeAmount:'充值金額',
    EnterRechargeAmount:'請輸入充值金額',
    PaymentMethod:'支付方式',
    SelectPaymentMethod:'請選擇支付方式',
    Cancellation:'取消',
    ConfirmRecharge:'確認充值',
    AdvertiserIdPlaceholder:"請輸入廣告帳戶ID",
    Reset:"重置",
    Search:"蒐索",
    StaticA:"統計資料：總花費：",
    StaticB:"，總廣告展現次數：",
    StatTimeDay:"日期",
    AdvertiserId:"廣告帳號名稱/廣告帳戶ID",
    Spend:"花費",
    Impressions:"廣告展現次數",
  },CheckBalance:{ // 錢包明細
    OrderNumber:'訂單號',
    ChangeType:'變更類型',
    OperationType:'操作類型',
    Reset:'重置',
    Search:'搜索',
    PreChangeAmount:'變更前金額',
    ChangeAmount:'變更金額',
    AmountAfterChange:'變更後金額',
    Remarks:'備注',
  },CheckCredit:{ // 錢包明細
    OrderNumber:'訂單號',
    ChangeType:'變更類型',
    OperationType:'操作類型',
    Reset:'重置',
    Search:'搜索',
    PreChangeAmount:'變更前金額',
    ChangeAmount:'變更金額',
    AmountAfterChange:'變更後金額',
    Remarks:'備注',
  },CheckAD:{ // 广告交易订单
    AccountType: '帳號類型',
    OrderNumber:'訂單號',
    AdvertisingName:'廣告名稱',
    OperationType:'操作類型',
    Status:'狀態',
    TransferStatus:'轉帳狀態',
    Reset:'重置',
    Search:'蒐索',
    PlatformType: '平臺類型',
    Account: '金額',
    TransferPlatform:'轉帳廣告帳戶平臺',
    TransferPlatformId:'轉帳廣告帳戶名稱/轉帳廣告帳戶Id',
    AccountId:'廣告帳戶名稱/廣告帳戶Id',
    Remarks:'備註',
    CreatedAt:'交易時間',
  },CheckPay:{ // 钱包充值订单
    Sn: '訂單號',
    Status:'訂單狀態',
    Reset:'重置',
    Search:'蒐索',
    CurrencyName: '幣種',
    PayTypeName: '支付方式',
    Amount: '充值金額',
    ExpiresAt:'訂單過期時間',
    PayTime:'支付時間',
    Operate:'操作',
    ImmediatePayment:'立即支付',
  },Settings:{
    Account:"帳號資訊",
    Company:"公司資訊",
    EmailBind:"郵箱綁定",
    PasswordUpdate:"密碼修改",
    Avatar:"頭像",
    Nickname:"用戶昵稱",
    NicknamePlaceholder:"請輸入用戶昵稱",
    NicknameRule:"用戶昵稱長度只支持（3~20）",
    SubmitForm:'提交',
    Upload:"圖片不能超過10M",
    UpdateSuccess:"修改成功",
    EnterpriseName:"企業名稱",
    EnterpriseEmail:"企業郵箱",
    EnterprisePhone:"連絡人電話",
    EnterpriseAddress:"企業地址",
    EnterpriseNamePlaceholder:"請輸入企業名稱",
    EnterpriseEmailPlaceholder:"請輸入企業郵箱",
    EnterprisePhonePlaceholder:"請輸入連絡人電話",
    EnterpriseAddressPlaceholder:"請輸入企業地址",
    Email:"郵箱",
    OldEmailCode:"郵箱驗證碼",
    OldEmailCodePlaceholder:"請輸入綁定郵箱驗證碼",
    SendSms :"發送驗證碼",
    BindEmail:"綁定郵箱",
    BindEmailPlaceholder:"請輸入綁定郵箱",
    BindEmailCode:"郵箱驗證碼",
    BindEmailCodePlaceholder:"請輸入郵箱驗證碼",
    SendCodeSuccess:"成功發送驗證碼",
    EmailBindSuccess:"郵箱綁定成功",
    EmailBindCodePlaceholder:"請輸入郵箱驗證碼",
    EmailSuccess:"請輸入正確郵箱",
    OldPassword:"老密碼",
    OldPasswordPlaceholder:"請輸入老密碼",
    Password:"新密碼",
    PasswordPlaceholder:"請輸入新密碼",
    RePassword:"確認密碼",
    RePasswordPlaceholder:"請輸入確認密碼",
    PasswordUpdateSuccess:"密碼修改成功",
  }
}