import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// import { useI18n } from "vue-i18n"; // 引入插件中的方法
// const { t } = useI18n(); // 解构 t 方法

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta:{
      title:'AdSeasy',
    },
    component:() => import('@/views/index.vue'),
    children:[
      {
        path: "/",
        name: "home",
        meta:{title:'首页'},
        component:() => import('@/views/home/index.vue')
      },
      {
        path: "/user/",
        name: "user",
        meta:{title: '账号设置'},
        component:() => import('@/views/user/index.vue')
      },
      {
        path: "/found/apply",
        name: "foundApply",
        meta:{title:'开户申请'},
        component:() => import('@/views/found/apply.vue')
      },
      {
        path: "/found/Facebook",
        name: "foundFacebook",
        meta:{title:'开户申请 - Facebook'},
        component:() => import('@/views/found/Facebook.vue')
      },
      {
        path: "/found/tiktok",
        name: "foundTikTok",
        meta:{title:'开户申请 - TikTok'},
        component:() => import('@/views/found/tiktok.vue')
      },
      {
        path: "/account/list",
        name: "accountList",
        meta:{title:'账户列表'},
        component:() => import('@/views/account/list.vue')
      },
      {
        path: "/account/record",
        name: "accountRecord",
        meta:{title:'开户记录'},
        component:() => import('@/views/account/record.vue')
      },
      {
        path: "/account/details",
        name: "accountDetails",
        meta:{title:'开户记录详情'},
        component:() => import('@/views/account/details.vue')
      },
      {
        path: "/account/recharge",
        name: "accountRecharge",
        meta:{title:'智能充值'},
        component:() => import('@/views/account/recharge.vue')
      },
      {
        path: "/account/monitor",
        name: "accountMonitor",
        meta:{title:'账户监控'},
        component:() => import('@/views/account/monitor.vue')
      },
      {
        path: "/check/wallet",
        name: "checkWallet",
        meta:{title:'钱包充值记录'},
        component:() => import('@/views/check/wallet.vue')
      },
      {
        path: "/advertisement/list",
        name: "advertisementList",
        meta:{title:'广告列表'},
        component:() => import('@/views/advertisement/list.vue')
      },
      {
        path: "/check/balance",
        name: "checkBalance",
        meta:{title:'钱包明细'},
        component:() => import('@/views/check/balance.vue')
      },
      {
        path: "/check/creditBalance",
        name: "checkCreditBalance",
        meta:{title:'信用余额明细'},
        component:() => import('@/views/check/creditBalance.vue')
      },
      {
        path: "/guide",
        name: "guide",
        meta:{title:'操作指南'},
        component:() => import('@/views/guide/index.vue')
      },
      {
        path: "/help",
        name: "help",
        meta:{ title: '帮助中心' },
        component:() => import('@/views/help/list.vue')
      },
      {
        path: "/help/detail",
        name: "helpDetail",
        meta:{ title: '帮助中心详情' },
        component:() => import('@/views/help/details.vue')
      }
    ]
  },
  {
    path: "/auth/login",
    name: "login",
    meta:{title: '登录'},
    component:() => import('@/views/auth/login.vue')
  },
  {
    path: "/auth/password",
    name: "password",
    meta:{ title: '修改密码' },
    component:() => import('@/views/auth/password.vue')
  },
  {
    path: "/auth/loginSms",
    name: "loginSms",
    meta:{ title: '验证码登录' },
    component:() => import('@/views/auth/loginSms.vue')
  },
  {
    path: "/auth/register",
    name: "register",
    meta:{ title: '注册' },
    component:() => import('@/views/auth/register.vue')
  },
  {
    path: "/auth/forget",
    name: "forget",
    meta:{  title:'忘记密码' },
    component:() => import('@/views/auth/forget.vue')
  },
  {
    path: "/article/policy",
    name: "policy",
    meta:{ title: '隐私信息' },
    component:() => import('@/views/article/policy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
