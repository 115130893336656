//我要用到的一些接口
import request from "@/utils/request"

//获取语言列表
export function lnguage() {
    return request({
        url: "/services/language",
        method: "GET"
    })
}

//获取站点配置信息
export function web_site() {
    return request({
        url: "/services/website",
        method: "GET"
    })
}

//获取区号列表
export function area_code() {
    return request({
        url: "/services/areaCode",
        method: "GET",
    })
}

//获取短信验证码
export function sms(data: object) {
    return request({
        url: "/services/sms",
        method: "POST",
        data,
    })
}
//获取登录短信验证码
export function loginsms(data: object) {
    return request({
        url: "/services/login/sms",
        method: "POST",
        data,
    })
}


//获取邮箱验证码
export function sms_email(data: object) {
    return request({
        url: "/services/email",
        method: "POST",
        data,
    })
}

//验证码验证
export function sms_check(data: object) {
    return request({
        url: "/services/check",
        method: "POST",
        data,
    })
}

//图片上传
export function upload(data: object) {
    return request({
        url: "/services/upload",
        method: "POST",
        data,
        headers:{'Content-Type': 'multipart/form-data'},
    })
}