<template>
  <el-config-provider :locale="locale" size="large">
    <!-- 全屏显示 -->
    <router-view />
    <el-affix :style="{ position: 'fixed', top: '50%', right: '0','z-index':'99' }">
      <img :src="keFu.wechat_kefu" style="width: 200px;position: absolute; right: 70px;bottom:40px;" v-show="keFu.isShow">
      <div style="background-color: #ffffff;">
        <img :src="require('/public/images/wechat.png')" style="width: 40px;"  v-show="!keFu.isHide" @mouseenter="keFu.isShow=true" @mouseleave="keFu.isShow=false">
        <el-icon v-show="!keFu.isHide" @click="keFu.isHide=true"><DArrowRight /></el-icon>
        <el-icon v-show="keFu.isHide" @click="keFu.isHide=false"><DArrowLeft /></el-icon>
      </div>
    </el-affix>
  </el-config-provider>
</template>
<script lang="ts" setup>
import { ElConfigProvider } from "element-plus";
import zhCN from 'element-plus/dist/locale/zh-cn'
import zhTW from 'element-plus/dist/locale/zh-tw'
import en from 'element-plus/dist/locale/en'
import {onUnmounted, reactive, ref} from "vue"
import { lnguage, web_site} from '@/api/services'
import { useStore } from 'vuex'
import {useI18n} from "vue-i18n";


const store = useStore()
const language = ref('zh-CN')
const locale = ref('zhCN')
//初始化项目
const i18n = useI18n();

//客服微信
const keFu = reactive({
    isHide:false,
    isShow:false,
    wechat_kefu:'',
});

initSystem()

function initSystem() {
  getLanguage()
  greet()
}

//获取语言
function getLanguage(){
  lnguage().then((res) => {
    store.commit('setLanguageList', res.data)
    if(store.state.selectLanguage == ''){
      store.commit('setSelectLanguage', res.data[0].code)
      store.commit('setSelectLanguageName', res.data[0].name)
    }
    language.value = res.data[0].code
  }).catch((err) => {
    console.log("err");
  });
}

//获取站点配置
// 系统信息
web_site().then((res) => {
  keFu.wechat_kefu = res.data.wechat_kefu
  store.commit('setWebSite', res.data)
}).catch((err) => {
  console.log("appinfo err");
});

function greet() {
  i18n.locale.value = store.state.selectLanguage.replace(/-/g, "")
  //console.log(store.state.selectLanguage.replace(/-/g, ""))
  //locale.value = store.state.selectLanguage.replace(/-/g, "");
  //locale.value = store.state.selectLanguage.replace(/-/g, "")
  if(store.state.selectLanguage === 'zh-CN'){
    locale.value = zhCN
  }
  if(store.state.selectLanguage === 'zh-TW'){
    locale.value = zhTW
  }
  if(store.state.selectLanguage === 'en'){
    locale.value = en
  }
}

</script>
<style scoped lang="scss">

</style>