import {createI18n,useI18n} from 'vue-i18n'
import en from './package/en'
import zhCN from './package/zhCn'
import zhTW from './package/zhTw'

  
  // 2. Create i18n instance with options
  const i18n = createI18n({
    locale: 'zhCN', // set locale
    fallbackLocale: 'en', // set fallback locale
    legacy: false, //
    globalInjection: true, // 全局使用 $t
    messages:{ en, zhCN , zhTW }, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
  })
  
export default i18n
export {useI18n}